import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Typography, withStyles, Paper } from '@material-ui/core'
import Layout from '../components/layout/Layout'

const styles = theme => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  topBanner: {
    paddingTop: '70px',
    background: 'linear-gradient(65deg, #198CFF 0%, #01EEFD 100%)',
    position: 'relative'
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3
  }
})

const page = (props) => {
  const classes = props.classes

  return (
    <Layout title='Ressource non trouvée' pathname={props.location.pathname}>
      <div className={classes.topBanner} />
      <div className={classes.layout}>
        <Paper className={classes.paper} elevation={1}>
          <Typography variant='h1'>
            404 - NOT FOUND
          </Typography>
          {/* <Img fixed={props.data.imgNotFound.childImageSharp.fixed} alt='404 - Not found' /> */}
          <Typography>
            Malgrès tous nos efforts, nous n'avons pu trouver la page demandée.
          </Typography>
        </Paper>
      </div>
    </Layout>
  )
}

export default withStyles(styles)(page)

export const query = graphql`
  query {
    imgNotFound: file(relativePath: { eq: "404.jpg" }) {
      childImageSharp {
        fixed(width: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
